/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { useSelector } from "react-redux";
import CustomePagination from "../../../../examples/Tables/CustomePagination";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";

function Transactions({ data, page, setPage, handleSearchCredits, count, isDashboard = false }) {
  const user = useSelector((store) => store.user);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchCredits(search);
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Credit History
        </MDTypography>
        {!isDashboard && (
          <MDBox display="flex" alignItems="center">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </MDBox>
        )}
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data.length > 0 ? (
            data.map((cr) => (
              <Transaction
                color={cr.type === "credit" ? "success" : "error"}
                icon={cr.type === "credit" ? "expand_less" : "expand_more"}
                name={user.data?.role === "admin" ? `${cr.user.name} - ${cr.note}` : cr.note}
                description={new Date(cr.createdAt).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
                value={`${cr.type === "credit" ? "+" : "-"} ${cr.amount}`}
              />
            ))
          ) : (
            <MDTypography variant="p" fontWeight="small" textAlign="center" fontSize={16}>
              No data found
            </MDTypography>
          )}
          {/* <Transaction
            color="success"
            icon="expand_less"
            name="HubSpot"
            description="26 March 2020, at 12:30 PM"
            value="+ $ 1,000"
          />
          <Transaction
            color="success"
            icon="expand_less"
            name="Creative Tim"
            description="26 March 2020, at 08:30 AM"
            value="+ $ 2,500"
          />
          <Transaction
            color="dark"
            icon="priority_high"
            name="Webflow"
            description="26 March 2020, at 05:00 AM"
            value="Pending"
          /> */}
          {!isDashboard && <CustomePagination count={count} page={page} setPage={setPage} />}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Transactions;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import MDBox from "components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDTypography from "components/MDTypography";
import logoXD from "assets/images/small-logos/logo-xd.svg";
import MDButton from "components/MDButton";
import { Icon, IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { apiV1 } from "utils/constants";
import { baseUrl } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import AddFile from "../AddFile";
export default function Data(list, setSelectedItems, setOpen, setSelectedItemsEdit, setModalType) {
  const user = useSelector((store) => store.user);
  const [openAddFileDialog, setOpenAddFileDialog] = useState(false);

  // Function to open the AddFile dialog
  const handleClickOpen = () => {
    setOpenAddFileDialog(true);
  };

  // Function to close the AddFile dialog
  const handleClose = () => {
    setOpenAddFileDialog(false);
  };
  const cookies = new Cookies();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };
  const getPresignedURL = async (projectId, type) => {
    const res = await axios.get(baseUrl + apiV1 + `/project/${projectId}/${type}`, { headers });
    if (res.data.url) {
      window.open(res.data.url, "_blank");
    }
  };
  const avatars = (members) =>
    members.map(([image, name]) => (
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Name", accessor: "name", width: "100px", align: "left" },
    { Header: "Email", accessor: "email", align: "center" },
    { Header: "Company", accessor: "company", align: "center" },
    { Header: "Level", accessor: "level", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  const dispatch = useDispatch();

  return {
    columns,
    rows: list.map((item, index) => {
      return {
        name: item.name,
        email: item.email,
        userId: item.id,
        company: item.company?.name,
        level: item.level,

        action: (
          <MDBox
            key={index}
            width="16rem"
            textAlign="left"
            style={{ gap: "12px", display: "flex", justifyContent: "center" }}
          >
            {/* Tooltip and IconButton for Edit action */}
            <Tooltip title="Edit">
              <IconButton
                style={{}}
                onClick={() => {
                  setOpen(true);
                  setModalType("Edit");
                  setSelectedItemsEdit(item);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {/* Tooltip and IconButton for Delete action */}
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  console.log("first");
                  setSelectedItems(item);
                }}
              >
                {" "}
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      };
    }),
  };
}

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextArea from "layouts/questionsList/textarea";
import TableComponent from "layouts/questionsList/table";

import { Button, IconButton, Input } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
const dialogStyle = {
  // minHeight: "80vh",
  width: "100%",
  // padding: "40px",
  margin: "0 auto",
};

const dialogActionsStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0",
};

const UnassignTask = ({ isUnassignModalOpen, setIsUnassignModalOpen, deleteAnswer }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(
        baseUrl + apiV1 + `/tasks/${isUnassignModalOpen?._id}/unassign`,
        {
          task: isUnassignModalOpen?._id,
        },
        { headers }
      );

      if (res.status === 200) {
        dispatch(setAlert({ message: `Task unassisgned!`, color: "success" }));
        deleteAnswer(isUnassignModalOpen);
        setIsUnassignModalOpen(false);
      } else {
        dispatch(setAlert({ message: `Some error occured`, color: "error" }));
      }
    } catch (e) {
      dispatch(setAlert({ message: `Some error occured`, color: "error" }));
      console.log({ message: e });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={isUnassignModalOpen ? true : false}
      onClose={() => {
        setIsUnassignModalOpen(null);
      }}
    >
      <Box style={dialogStyle}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
            {isUnassignModalOpen?.question?.questionText}

            <Icon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setIsUnassignModalOpen(null);
              }}
            >
              close
            </Icon>
          </Box>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to unassign{" "}
          {isUnassignModalOpen?.assignee?.map((item) => item.name)?.join(", ")} (
          {isUnassignModalOpen?.assignee?.map((item) => item.email)?.join(", ")})
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <MDBox
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
              alignItems: "end",
              width: "100%",
            }}
          >
            <MDButton onClick={handleSubmit} variant="gradient" color="info">
              Unassign
            </MDButton>
          </MDBox>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UnassignTask;

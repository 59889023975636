import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { creditsPerProject } from "utils/constants";
import { setUser } from "reduxToolkit/user/userSlice";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

const AddTask = ({
  saveData,
  setSaveData,
  open,
  setOpen,
  onSuccessPost,
  selectedRows,
  intilaScreen = false,
}) => {
  const cookies = new Cookies();
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [dropData, setDropData] = useState({
    name: "",
    email: "",
    level: "Level 3",
    // file: null,
  });

  const [showAlert, setShowAlert] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef(null); // Corrected
  const buttonStyle = {
    marginRight: "16px",
    zIndex: 0,
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const fetchUsers = async () => {
      const res = await axios.get(baseUrl + apiV1 + `/users?limit=1000`, {
        headers,
      });

      setOptions(res.data?.results?.map((user) => ({ value: user.id, label: user.name })));
    };

    fetchUsers();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const addAnotherUserButtonContainer = {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  };
  const dialogStyle = {
    width: "500px",
    padding: "12px",
    margin: "0 auto",
  };
  const dialogContentStyle = {
    paddingTop: "12px",
  };

  const dialogActionsStyle = {
    display: "flex",
    alignItems: "center",
  };

  const projectListHeaderStyle = {
    flex: 1,
    textAlign: "left",
    padding: "8px",
    marginRight: "16px",
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (file) => {
    setDropData({ ...dropData, file });
  };

  const handleNameChange = (e) => {
    setDropData({ ...dropData, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    setDropData({ ...dropData, email: e.target.value });
  };

  const handleLevelChange = (e) => {
    setDropData({ ...dropData, level: e.target.value });
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
  };

  useEffect(() => {
    setSelectedOption(null);
  }, [open]);

  const handleCreate = async () => {
    if (!selectedOption) {
      dispatch(setAlert({ message: "please select user first", color: "error" }));
      return;
    }

    setLoading(true);
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(
        baseUrl + apiV1 + "/questions/assign",
        {
          users: selectedOption.value,
          questions: selectedRows,
        },
        { headers }
      );

      // try {
      //   const res = await axios.post(baseUrl + apiV1 + "/users/adduser", dropData, { headers });
      setLoading(false);
      if (res.status === 200) {
        dispatch(setAlert({ message: `task assigned successfully!`, color: "success" }));
        setOpen(false);
        // onSuccessPost();
      } else {
        // dispatch(setAlert({ message: res.data.message, color: "error" }));
        // setSuccess(false);
      }
    } catch (e) {
      console.log({ message: e });
      dispatch(setAlert({ message: e.response.data.message, color: "error" }));
      // setSuccess(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setDropData({ name: "", file: null });
    setOpen(false);
    setShowAlert(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box style={dialogStyle}>
        <DialogTitle>Assign Questions to</DialogTitle>
        <DialogContent>
          <Box>
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Select an option"
              // isMulti
              isClearable
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <MDBox style={{ alignItems: "center", alignSelf: "center" }}>
            <MDButton onClick={handleCreate} variant="gradient" color="info" disabled={loading}>
              {loading && <CircularProgress size={16} color="inherit" sx={{ marginRight: 2 }} />}
              Assign
            </MDButton>
          </MDBox>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddTask;

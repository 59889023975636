/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export default function data(list, setSelectedCompany) {
  const columns = [
    { Header: "Company Name", accessor: "company", width: "100px", align: "left" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return {
    columns,
    rows: list.map((item) => {
      console.log({ item });
      return {
        company: item.company?.name,
        action: (
          <MDBox
            width="16rem"
            textAlign="left"
            style={{ gap: "12px", display: "flex", justifyContent: "center" }}
          >
            <MDButton
              color="info"
              onClick={() => {
                setSelectedCompany(item._id);
              }}
              style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
            >
              View tasks
            </MDButton>
          </MDBox>
        ),
      };
    }),
  };
}

import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import { useForm } from "react-hook-form";
import MDInput from "../../../../components/MDInput";
import MDTypography from "../../../../components/MDTypography";

const CreditsPopUp = ({ handleClose }) => {
  const submit = (data) => {
    handleClose();
  };
  const dialogActionsStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };
  const dialogStyle = {
    height: "350px",
    width: "500px",
    padding: "40px",
    margin: "0 auto",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { amount: 100 }, mode: "onChange" });
  return (
    <Dialog open={true} onClose={handleClose}>
      <Box style={dialogStyle}>
        <DialogTitle>Add Credits</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter number of credits you want to add.</DialogContentText>
          <MDBox mt={2} mb={2}>
            <MDInput
              autoFocus
              margin="dense"
              id="credits"
              label="Enter Credits"
              type="text"
              fullWidth
              {...register("credits", {
                required: "Amount is required",
                pattern: {
                  value: /^(?:[1-9][0-9]{1,2}|1000)$/,
                  message: "Invalid Amount. Please enter a number between 10 and 1000.",
                },
              })}
            />
            {errors?.credits ? (
              <MDBox mb={-4}>
                <MDTypography variant="button" color="error">
                  {errors?.credits?.message}
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <MDBox mt={errors?.credits ? -2.5 : 0}>
            <MDButton onClick={handleClose} variant="gradient" color="error">
              Cancel
            </MDButton>
          </MDBox>
          <MDBox mt={errors?.credits ? -2.5 : 0}>
            <MDButton onClick={handleSubmit(submit)} variant="gradient" color="success">
              Save
            </MDButton>
          </MDBox>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreditsPopUp;

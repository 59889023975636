/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, TextField } from "@mui/material";

// import filterIcon

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Fields/data";
import MDButton from "components/MDButton";
import AddFile from "./AddFile";
import ViewLogs from "./viewLogs";
import userData from "./data/userData";
import { useLocation, useNavigate } from "react-router-dom";
import projectsList from "./data/partData";
import AddTask from "./AddTask";
import MultiSelectTable from "./MultiSelectTable";
import partData from "./data/partData";
import AddAnswer from "./AddAnswer";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Fields({
  saveData,
  dataType = null,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  setSelectedRows,
  selectedRows,
  count,
  setPage,
  page,
  isDashboard = false,
  isAdmin = false,
  title = "Modules",
}) {
  const [isAddAnswerOpen, setIsAddAnswerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const navigate = useNavigate();
  const { columns, rows } = data(saveData, setSelectedSection);
  const [partList, setPartList] = useState({ columns: [], rows: [] });

  const [menu, setMenu] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (selectedSection) {
      setPartList(partData(selectedSection, setSelectedPart));
    }
  }, [selectedSection]);

  const handleSearch = (searchText) => {
    handleSearchChange(searchText);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex" alignItems="center">
          {selectedSection && (
            <Button
              style={{
                padding: "0px",
                marginRight: 10,
                minWidth: "auto",
                margin: "10",
                textTransform: "none",
                fontWeight: 400,
                borderRadius: "12",
              }}
              variant="text"
              onClick={() => {
                if (selectedPart) {
                  setSelectedPart(null);
                } else if (selectedSection) {
                  setSelectedSection(null);
                }
              }}
              p={0}
            >
              <ArrowBackIcon
                sx={{
                  fontSize: "2rem",
                  color: "black",
                  marginRight: 0,
                }}
              />
            </Button>
          )}

          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center">
          {/* {!isDashboard && (
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )} */}

          {selectedRows?.length > 0 && (
            <MDBox color="text" px={2}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => setOpen(true)}
                style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
              >
                Assign to User
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox>
        {selectedSection === null ? (
          <DataTable
            count={count}
            pageNumber={page}
            setPage={setPage}
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={{
              defaultValue: 100,
            }}
            isDashboard={isDashboard}
          />
        ) : (
          <>
            {selectedPart?.length > 0 ? (
              <MultiSelectTable
                saveData={selectedPart}
                setSelectedRows={setSelectedRows}
                setSelectedQuestion={setSelectedQuestion}
              />
            ) : (
              <DataTable
                count={count}
                pageNumber={page}
                setPage={setPage}
                table={{ ...partList }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
                isDashboard={isDashboard}
              />
            )}
          </>
        )}
      </MDBox>
      <AddTask
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={onSuccessPost}
        selectedRows={selectedRows}
      />
      <AddAnswer
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        task={{}}
        isFields={true}
      />

      {/* <ViewLogs open={openErrorLogs} setOpen={setOpenErrorLogs} projectName={projectName} /> */}
    </Card>
  );
}

export default Fields;

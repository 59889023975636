import {
  newCompanies,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { creditsPerProject } from "utils/constants";
import { setUser } from "reduxToolkit/user/userSlice";

const filter = createFilterOptions();

const AddFile = ({
  companies,
  saveData,
  setSaveData,
  open,
  setOpen,
  onSuccessPost,
  modalType,
  selectedUser,
}) => {
  const [value, setValue] = React.useState(null);
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [dropData, setDropData] = useState({
    name: "",
    email: "",
    company: "",
    level: "Level 3",
    // file: null,
  });

  useEffect(() => {
    if (selectedUser && modalType === "Edit") {
      setDropData({
        id: selectedUser.userId,
        name: selectedUser.name,
        email: selectedUser.email,
        company: selectedUser.company?.name,
        level: selectedUser.level,
      });
    } else {
      setDropData({
        name: "",
        email: "",
        company: "",
        level: "",
      });
    }
  }, [selectedUser, modalType]);

  const [showAlert, setShowAlert] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef(null);
  const buttonStyle = {
    marginRight: "16px",
    zIndex: 0,
  };

  const addAnotherUserButtonContainer = {
    display: "flex",
    alignItems: "center",
    margin: "20px 0",
  };
  const dialogStyle = {
    minHeight: "500px",
    width: "500px",
    padding: "12px",
    margin: "0 auto",
  };
  const dialogContentStyle = {
    paddingTop: "12px",
  };

  const dialogActionsStyle = {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0",
  };

  const projectListHeaderStyle = {
    flex: 1,
    textAlign: "left",
    padding: "8px",
    marginRight: "16px",
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (file) => {
    setDropData({ ...dropData, file });
  };

  const handleNameChange = (e) => {
    setDropData({ ...dropData, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    setDropData({ ...dropData, email: e.target.value });
  };

  const handleLevelChange = (e) => {
    setDropData({ ...dropData, level: e.target.value });
  };

  const handleCreate = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      if (modalType === "Add") {
        // If modalType is "Add," make a POST request
        const res = await axios.post(baseUrl + apiV1 + "/users/adduser", dropData, { headers });

        if (res.status === 201) {
          setSaveData((prevState) => [res.data, ...prevState]);
          dispatch(setAlert({ message: `User added successfully!`, color: "success" }));
          setOpen(false);
          setDropData({
            name: "",
            email: "",
            level: "Level 3",
            // file: null,
          });
          onSuccessPost();
        } else {
          dispatch(setAlert({ message: res.data.message, color: "error" }));
          setSuccess(false);
          setLoading(false);
        }
      } else if (modalType === "Edit") {
        const res = await axios.put(
          baseUrl + apiV1 + `/users/updateuser/${selectedUser.id}`,
          { ...dropData, userId: selectedUser.id },
          { headers }
        );

        if (res.status === 200) {
          setSaveData((prevState) => {
            let index = prevState.findIndex((user) => user.id === res.data.id);
            prevState[index] = res.data;
            return [...prevState];
          });
          dispatch(setAlert({ message: `User updated successfully!`, color: "success" }));
          setOpen(false);
          onSuccessPost();
        } else {
          dispatch(setAlert({ message: e.response?.data?.message || e.message, color: "error" }));
          setSuccess(false);
          setLoading(false);
        }
      }
    } catch (e) {
      dispatch(setAlert({ message: e.response?.data?.message || e.message, color: "error" }));
      setSuccess(false);
      setLoading(false);
    }
  };

  const createCompany = async (companyName) => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      // If modalType is "Add," make a POST request
      const res = await axios.post(
        baseUrl + apiV1 + "/companies",
        { name: companyName, userId: dropData.id },
        { headers }
      );

      if (res.status === 201) {
        setDropData((prevState) => ({ ...prevState, company: companyName }));
      } else {
        dispatch(setAlert({ message: res.data.message, color: "error" }));
      }
    } catch (e) {
      dispatch(setAlert({ message: e.response?.data?.message || e.message, color: "error" }));
    }
  };

  const handleCancelEdit = () => {
    setDropData({
      name: selectedUser.name,
      email: selectedUser.email,
      company: selectedUser.company?.name,
      level: selectedUser.level,
    });
    setOpen(false);
    setShowAlert(false);
  };

  const handleCancelAdd = () => {
    setDropData({
      name: "",
      email: "",
      company: "",
      level: "",
    });
    setOpen(false);
    setShowAlert(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box style={dialogStyle}>
        <DialogTitle>{modalType === "Add" ? "Add new user" : "Update user"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Name"
            type="text"
            fullWidth
            value={dropData.name}
            onChange={handleNameChange}
          />
          {modalType === "Add" ? (
            <TextField
              margin="dense"
              id="name"
              label="Enter Email"
              type="text"
              fullWidth
              value={dropData.email}
              onChange={handleEmailChange}
            />
          ) : (
            <TextField
              disabled
              margin="dense"
              id="name"
              label="Enter Email"
              type="text"
              fullWidth
              value={dropData.email}
              onChange={handleEmailChange}
            />
          )}

          <CreatableSelect
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                marginTop: "10px",
                fontSize: 14,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 1000000,
                fontSize: 15,
              }),
            }}
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(newValue) => {
              if (newValue && newValue.value) {
                setDropData((prevState) => ({ ...prevState, company: newValue.value }));
              } else {
                console.error("Invalid or undefined newValue:", newValue);
              }
            }}
            onCreateOption={createCompany}
            options={companies.map((company) => ({ value: company.name, label: company.name }))}
            value={
              dropData.company
                ? { label: dropData.company, value: dropData.company }
                : "select company"
            }
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="level-label">Select Level</InputLabel>
            <Select
              labelId="level-label"
              id="level"
              value={dropData.level}
              onChange={handleLevelChange}
              sx={{ height: "40px" }}
              label="Select Level"
            >
              <MenuItem value="Level 1">Level 1 (Department head)</MenuItem>
              <MenuItem value="Level 2">Level 2 (Unit head)</MenuItem>
              <MenuItem value="Level 3">Level 3 (Company coordinator)</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={dialogActionsStyle}>
          <MDBox mb={1}>
            <MDButton
              onClick={modalType === "Add" ? handleCancelAdd : handleCancelEdit}
              variant="gradient"
              color="error"
            >
              {modalType === "Add" ? "Cancel" : "Cancel"}
            </MDButton>
          </MDBox>
          <MDBox mb={1}>
            <MDButton onClick={handleCreate} variant="gradient" color="info">
              {modalType === "Add" ? "Add" : "Update"} User
            </MDButton>
          </MDBox>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddFile;

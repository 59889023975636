/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "layouts/transactions/components/Invoice";
import { useSelector } from "react-redux";
import CustomePagination from "../../../../examples/Tables/CustomePagination";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";

function Invoices({ data, page, setPage, handleSearchPayment, count, isDashboard = false }) {
  const user = useSelector((store) => store.user);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchPayment(search);
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Payments
        </MDTypography>
        {!isDashboard && (
          <MDBox display="flex" alignItems="center">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </MDBox>
        )}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {data.length > 0 ? (
            data?.map((payment) => (
              <Invoice
                date={
                  user?.data?.role === "admin"
                    ? `${new Date(payment.createdAt).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })} - ${payment.user.name}`
                    : new Date(payment.createdAt).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                }
                id={payment.receipt?.replace("receipt_order_", "#")}
                price={payment.amount}
                status={payment.status}
              />
            ))
          ) : (
            <MDTypography variant="p" fontWeight="small" textAlign="center" fontSize={16}>
              No data found
            </MDTypography>
          )}
          {/* <Invoice date="March, 01, 2020" id="#MS-415646" price="$180" />
          <Invoice date="February, 10, 2021" id="#RV-126749" price="$250" />
          <Invoice date="April, 05, 2020" id="#QW-103578" price="$120" />
          <Invoice date="June, 25, 2019" id="#MS-415646" price="$180" />
          <Invoice date="March, 01, 2019" id="#AR-803481" price="$300" noGutter /> */}
          {/* <CustomePagination count={count} page={page} setPage={setPage} /> */}
          {!isDashboard && <CustomePagination count={count} page={page} setPage={setPage} />}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Invoices;

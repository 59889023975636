import React from "react";
import { useParams } from "react-router-dom";
import TextArea from "./textarea";
import TableComponent from "./table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const QuestionPage = () => {
  const { id } = useParams();

  console.log("Question ID:", id);

  const sampleDict = {
    questionText: "Turnover rate for permanent employees and workers",
    questionDesc: "",
    group: "",
    section: "Section A: General Disclosures",
    part: "Employees",
    dept: "HR",
    questionType: "table",
    columns: [
      "",
      ["Current FY", ["Male", "Female", "Total"]],
      ["Last FY", ["Male", "Female", "Total"]],
      ["Previous Last FY", ["Male", "Female", "Total"]],
    ],
    rows: ["Permanent Employees", "Permanent Workers"],
    formulae: ["sum", "Total"],

    // _id: "65bccb843637b5eae6f5ad94",
    // questionText: "Details of business activities (accounting for 90% of the turnover)",
    // questionDesc: "",
    // section: "Section A: General Disclosures",
    // part: "Products/Services",
    // dept: "Finance",
    // questionType: "table",
    // columns: [
    //   "S. No.",
    //   "Description of Main Activity",
    //   "Description of Business Activity",
    //   "% of Turnover of entity",
    // ],
    // rows: [
    //   // Add sample rows here if available
    // ],
  };

  const selectedQuestion = {
    questionText: sampleDict.questionText,
    type: sampleDict.questionType,
    columns: sampleDict.columns,
    rows: sampleDict.rows,
  };

  return (
    <DashboardLayout>
      <div style={{ border: "2px solid #000", padding: "10px" }}>
        <h1 style={{ textAlign: "center" }}>{selectedQuestion.questionText}</h1>

        {selectedQuestion.type === "text" && (
          <div>
            <p>Rendering Text Component</p>
            <TextArea />
          </div>
        )}

        {selectedQuestion.type === "table" && (
          <div>
            <p>Rendering Table Component</p>
            <TableComponent columns={selectedQuestion.columns} rows={selectedQuestion.rows} />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default QuestionPage;

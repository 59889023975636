import React, { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";

const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSignup = async ({ name, email, password, onSuccess = null, onError = null }) => {
    try {
      setLoading(true);
      const res = await axios.post(baseUrl + apiV1 + "/auth/register", {
        email: email,
        password: password,
        name: name,
      });
      if (res.status === StatusCodes.CREATED) {
        dispatch(setAlert({ message: "OTP has been send to your email", color: "success" }));
        onSuccess && onSuccess();
      } else {
        console.log("error", res.data.error.response.data.error);
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.data?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log("error", e.response.data.error);
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response.data.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleSignup };
};

export default useSignup;

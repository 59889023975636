import React, { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";
import Cookies from "universal-cookie";

const useGetPaymentHistory = () => {
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const handleGetPaymentHistory = async ({
    limit = 5,
    page = 1,
    search = "",
    userId,
    onSuccess = null,
    onError = null,
  }) => {
    try {
      setLoading(true);
      const res = await axios.get(
        baseUrl +
          apiV1 +
          `/payment/orders?projectBy=createdAt,updatedAt,amount,status,order,receipt,user&sortBy=createdAt:desc&limit=${limit}&page=${page}${
            search !== "" ? `&query=${search}` : ""
          }${userId ? `&userId=${userId}` : ""}`,
        {
          headers,
        }
      );
      if (res.status === StatusCodes.OK) {
        onSuccess && onSuccess(res);
      } else {
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleGetPaymentHistory };
};

export default useGetPaymentHistory;

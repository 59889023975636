import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Footer from "../../../examples/Footer";
import UserProject from "./Components/UserProject";
import Grid from "@mui/material/Grid";
import DetailsForm from "./Components/DetailsForm";
import UserPayments from "./Components/UserPayments";
import UserCredits from "./Components/UserCredits";
import CreditsPopUp from "./Components/CreditsPopUp";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

const User = () => {
  const [openCreditsPopUp, setOpenCreditsPopUp] = useState(false);
  const { id } = useParams();

  const [user, setUser] = useState({});
  const cookies = new Cookies();

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const fetchUser = async () => {
    const res = await axios.get(baseUrl + apiV1 + `/users/userinfo?userId=${id}`, {
      headers,
    });
    setUser(res.data);
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar
        isUser={true}
        onAddCreditsClick={() => setOpenCreditsPopUp(true)}
        credits={user.credits}
      />
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        {openCreditsPopUp ? <CreditsPopUp handleClose={() => setOpenCreditsPopUp(false)} /> : null}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserProject userId={id} />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserCredits userId={id} />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserPayments userId={id} />
          </Grid>
          <Grid item xs={12}>
            <DetailsForm user={user} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default User;

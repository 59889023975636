import React from "react";
import useForgotPassword from "../../../../../hook/useForgotPassword";
import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import { useForm } from "react-hook-form";

const ForgotPasswordFileds = ({ page, setPage }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleForgotPassword, loading } = useForgotPassword();
  const submit = (data) => {
    handleForgotPassword({ email: data.email, onSuccess: () => setPage(data.email) });
  };
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Reset Password
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          You will receive an e-mail in maximum 60 seconds
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={4}>
            <MDInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors?.email ? (
              <MDTypography variant="button" color="error">
                {errors?.email?.message}
              </MDTypography>
            ) : null}
          </MDBox>
          <MDBox mt={6} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit(submit)}>
              reset
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ForgotPasswordFileds;

import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Button, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { bool } from "prop-types";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

//for custom table

export default function MultiSelectTable({
  saveData,
  role,
  setSelectedQuestion,
  setTask,
  setIsUnassignModalOpen,
  customquestion,
  setCustomquestion,
  task,
  selectedQuestion,
}) {
  const handleQuestionClick = async (item, task) => {
    setSelectedQuestion(item);
    setTask(task);
  };

  const cookies = new Cookies();

  let columns = [
    {
      field: "questionText",
      headerName: "Question",
      minWidth: 700,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              handleQuestionClick(params.row?.question, params.row);
            }}
            color="primary"
            sx={{
              display: "block",
            }}
          >
            {params.row?.question?.questionText}
          </Button>
        );
      },
    },
    {
      field: "taskStatus",
      headerName: "Status",
      renderCell: (params) => {
        return <p style={{ textTransform: "capitalize" }}>{params.row?.taskStatus}</p>;
      },
    },
    role === "admin"
      ? {
          field: "assigneeName",
          headerName: "Assigned To",
          minWidth: 200,
          renderCell: (params) => (
            <MDBox style={{ gap: 10, display: "flex" }}>
              {params?.row?.assignee?.map((item) => (
                <Tooltip title={item?.name}>
                  <Avatar
                    sx={{
                      bgcolor: "#1976d2",
                      width: 25,
                      height: 25,
                      fontWeight: 400,
                      padding: 1,
                      fontSize: "16px",
                    }}
                  >
                    {item?.name?.charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
            </MDBox>
          ),
        }
      : {},
    role === "admin"
      ? {
          field: "action",
          headerName: "Action",
          minWidth: 200,
          renderCell: (params) => (
            <MDButton
              variant="contained"
              color="secondary"
              onClick={() => setIsUnassignModalOpen(params.row)}
            >
              Unassign
            </MDButton>
          ),
        }
      : {},
    //   {
    //     field: "fullName",
    //     headerName: "Assign To",
    //     description: "This column has a value getter and is not sortable.",
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    //   },
  ];

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const fetchTasks = async () => {
      const res = await axios.get(
        baseUrl + apiV1 + `/custom?type=${task?.question?.parentQuestion}`,
        {
          headers,
        }
      );

      setCustomquestion(res.data?.tasks[0]);
    };

    if (task?.question?.parentQuestion) {
      // fetchTasks();
    }
  }, [task]);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={
          saveData.length > 0
            ? [
                ...saveData
                  ?.map((item) => ({
                    ...item,
                    questionText: item?.question?.questionText,
                    assigneeName: item.assignee?.map((item) => item.name)?.join(" "),
                  }))
                  .sort((a, b) => {
                    return a.question.key - b.question.key;
                  }),
              ]
            : []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[50, 100]}
        row
      />
    </div>
  );
}

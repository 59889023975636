import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextArea from "layouts/questionsList/textarea";
import TableComponent from "layouts/questionsList/table";
import { Button, IconButton, Input } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";
const dialogStyle = {
  // minHeight: "80vh",
  width: "100%",
  // padding: "40px",
  margin: "0 auto",
};

const dialogActionsStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0",
};

const AddAnswer = ({
  setCustomquestion,
  setSelectedQuestion,
  task,
  selectedQuestion,
  updateAnswer,
  isFields = false,
  setTask,
}) => {
  const [answers, setAnswers] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const cookies = new Cookies();

  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const fetchCustomTasks = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      baseUrl + apiV1 + `/custom?type=${task?.question?.parentQuestion}`,
      {
        headers,
      }
    );

    console.log("task", res?.data?.tasks[0]);

    setCustomquestion(res?.data?.tasks[0]);
    setSelectedQuestion(null);
  };

  // client 51, 67, 71

  // 65, 66, 113, 115
  useEffect(() => {
    console.log({ task });
    if (task?.answer) {
      setAnswers(task.answer?.answerText);
    }
    //  else if (task?.customanswer) {
    //   setAnswers(task.customanswer?.answerText);
    // }
    else {
      setAnswers([]);
    }
    setSelectedFiles([]);
  }, [task]);

  useEffect(() => {
    console.log({ selectedQuestion });
  }, [selectedQuestion]);

  const handleSubmit = async (bypassEnterData = false) => {
    console.log({ bypassEnterData });
    if (!selectedQuestion?.parentQuestion || bypassEnterData) {
      // console.log("Submitting data...", answers, task);

      // const formData = new FormData();

      // formData.append("taskId", task._id);
      // formData.append("answerText", answers);

      // // Append selected files to the FormData object
      // for (const file of selectedFiles) {
      //   formData.append("files", file);
      // }

      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json", // Specify content type as multipart/form-data for file uploads
      };

      try {
        const res = await axios.post(
          `${baseUrl}${apiV1}/answers/${
            selectedQuestion._id ? selectedQuestion._id : selectedQuestion.id
          }`,
          {
            taskId: task._id,
            answerText: answers,
          },
          {
            headers,
          }
        );

        // console.log("Response:", res);

        if (res.status === 201) {
          dispatch(setAlert({ message: `Answer recorded successfully!`, color: "success" }));
          setSelectedQuestion(null);
          updateAnswer(res.data);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    } else {
      fetchCustomTasks();
    }
  };

  const handleDraft = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(
        baseUrl +
          apiV1 +
          `/answers/${selectedQuestion._id ? selectedQuestion._id : selectedQuestion.id}`,
        {
          taskId: task._id,
          answerText: answers,
          isDraft: true,
        },
        { headers }
      );

      if (res.status === 201) {
        dispatch(setAlert({ message: `Answer drafted successfully!`, color: "success" }));
        updateAnswer(res.data);
        setSelectedQuestion(null);
      }
    } catch (e) {
      console.log({ message: e });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      open={selectedQuestion ? true : false}
      onClose={() => {
        setSelectedQuestion(null);
      }}
    >
      <Box style={dialogStyle}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
            {selectedQuestion?.questionText}
            <Icon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedQuestion(null);
              }}
            >
              close
            </Icon>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedQuestion?.questionType === "text" && (
            <TextArea answers={answers} setAnswers={setAnswers} />
          )}

          {(selectedQuestion?.questionType === "table" ||
            selectedQuestion?.questionType === "formulae") && (
            <TableComponent
              questionId={selectedQuestion?.key}
              columns={selectedQuestion?.columns}
              rows={selectedQuestion?.rows}
              tableSections={selectedQuestion?.table_sections}
              formulae={selectedQuestion?.formulae}
              questionType={selectedQuestion?.questionType}
              answers={answers}
              setAnswers={setAnswers}
            />
          )}
          {/* <div>
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: ".pdf, .doc, .docx", multiple: true }} // Specify accepted file types and allow multiple files
              style={{ display: "none" }} // Hide the input visually
              id="file-upload-input"
            />
            <label htmlFor="file-upload-input">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
            {selectedFiles.length > 0 ? (
              <div style={{ marginLeft: "10px" }}>
                {selectedFiles.map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
              </div>
            ) : (
              "No files"
            )}
          </div> */}
        </DialogContent>

        {!isFields && (
          <DialogActions style={dialogActionsStyle}>
            <MDBox
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
                alignItems: "end",
                width: "100%",
              }}
            >
              <MDButton onClick={handleDraft} variant="outlined" color="secondary">
                Save as draft
              </MDButton>
              <MDButton
                onClick={() => {
                  handleSubmit();
                }}
                variant="gradient"
                color="info"
              >
                {selectedQuestion?.parentQuestion
                  ? "Enter Data"
                  : task.answer
                  ? "Update"
                  : "Submit"}
              </MDButton>
              {selectedQuestion?.parentQuestion && (
                <MDButton
                  onClick={() => {
                    handleSubmit(true);
                  }}
                  variant="gradient"
                  color="info"
                >
                  {task.answer ? "Update" : "Submit"}
                </MDButton>
              )}
            </MDBox>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default AddAnswer;

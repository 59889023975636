import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "reduxToolkit/user/userSlice";
import { apiV1 } from "utils/constants";
import { baseUrl } from "utils/constants";
import { isTokenValid } from "utils/tokenValidator";
import { setAlert } from "../reduxToolkit/alert/alertSlice";
import { ReasonPhrases } from "http-status-codes";
import Cookies from "universal-cookie";

const AuthValidator = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    const getUser = async () => {
      const isToken = isTokenValid();
      if (isToken) {
        try {
          const headers = {
            Authorization: `Bearer ${isToken}`,
            "Content-Type": "application/json",
          };

          const res = await axios.get(baseUrl + apiV1 + "/users/userinfo", {
            headers,
          });

          dispatch(setUser(res.data));
          setIsLoading(false);
        } catch (e) {
          if (e.response.status === 401) {
            cookies.remove("token");
            navigate("/authentication/sign-in");
          }
          dispatch(
            setAlert({
              message: e.response?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
              color: "error",
            })
          );
          navigate("/authentication/sign-in");

          console.log(e);
        }
      } else {
        cookies.remove("token");
        // navigate("/authentication/sign-in");

        navigate("/authentication/sign-in");
      }
    };
    getUser();
  }, []);
  return isLoading ? <div></div> : <div>{children}</div>;
};

export default AuthValidator;

import Icon from "@mui/material/Icon";
import AuthValidator from "./layouts/AuthValidator";
import Dashboard from "./layouts/dashboard";
import ProjectList from "./layouts/project";
import TransactionsTable from "./layouts/transactions";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import Payment from "./layouts/payment";
import AdminDashboard from "./layouts/Admin/AdminDashboard";
import Users from "./layouts/Admin/AdminUsers";
import User from "./layouts/Admin/User";
import AdminUsers from "./layouts/Admin/AdminUsers";
import AddCardIcon from "@mui/icons-material/AddCard";
import AdminFields from "layouts/Admin/AdminFields";
import AdminTasks from "layouts/Admin/AdminTasks";

export const userSideBar = [
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tasks",
    component: (
      <AuthValidator>
        <AdminTasks />
      </AuthValidator>
    ),
  },
];

export const adminSideBar = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/project",
  //   // component: isTokenValid() ? <Dashboard /> : <Redirect />,
  //   component: (
  //     <AuthValidator>
  //       <AdminDashboard />
  //     </AuthValidator>
  //   ),
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <AuthValidator>{/* <AdminUsers /> */}</AuthValidator>,
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "/users",
    component: (
      <AuthValidator>
        <AdminUsers />
      </AuthValidator>
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: (
  //     <AuthValidator>
  //       <Profile />
  //     </AuthValidator>
  //   ),
  // },
  {
    type: "collapse",
    name: "Modules",
    key: "modules",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/modules",
    component: (
      <AuthValidator>
        <AdminFields />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tasks",
    component: (
      <AuthValidator>
        <AdminTasks />
      </AuthValidator>
    ),
  },
];

import React, { useEffect, useState } from "react";
// import { dummyData } from ".";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Projects from "layouts/dashboard/components/Projects";
import Fields from "layouts/dashboard/components/Fields";
import Cookies from "universal-cookie";
import useGetAllQuestions from "hook/useGetAllQuestions";
import Tasks from "layouts/dashboard/components/Tasks";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

const AdminTasks = () => {
  const [saveData, setSaveData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const { handleGetQuestions } = useGetAllQuestions();
  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
  };

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const fetchTasks = async () => {
    const res = await axios.get(baseUrl + apiV1 + `/tasks?page=0&limit=9999999999`, {
      headers,
    });

    setSaveData(res.data?.tasks);
  };

  useEffect(() => {
    // handleGetQuestions({
    //   limit: 10000,
    //   page,
    //   search: searchQuery,
    //   onSuccess: (res) => {
    //     console.log(res.data);
    //     setSaveData(res.data.results);
    //     setCount(res.data.totalPages);
    //   },
    // });

    fetchTasks();
    // setSaveData([...saveData, questionTable]);
  }, [page, searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <Tasks
          dataType="users"
          setPage={setPage}
          count={count}
          page={page}
          saveData={saveData}
          setSaveData={setSaveData}
          handleSearchChange={handleSearchChange}
          open={open}
          setOpen={setOpen}
          onSuccessPost={() => console.log("first")}
          isAdmin
          title="Tasks"
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdminTasks;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import useForgotPassword from "../../../../hook/useForgotPassword";
import ForgotPasswordFileds from "./components/ForgotPasswordFileds";
import ConfirmNewPasswordFields from "./components/ConfirmNewPasswordFields";
import { useState } from "react";

function ResetPassword() {
  const [page, setPage] = useState(0);
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      {page === 0 ? (
        <ForgotPasswordFileds page={page} setPage={setPage} />
      ) : (
        <ConfirmNewPasswordFields page={page} setPage={setPage} />
      )}
    </CoverLayout>
  );
}

export default ResetPassword;

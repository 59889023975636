import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Projects from "../../dashboard/components/Projects";
import Transactions from "../../transactions/components/Transactions";
import AddFile from "../../dashboard/components/Projects/AddFile";
import Cookies from "universal-cookie";
import useGetCreditHistory from "../../../hook/useGetCreditHistory";
import useGetAllProjects from "../../../hook/useGetAllProjects";
import Footer from "../../../examples/Footer";
import useGetPaymentHistory from "hook/useGetPaymentHistory";
import Invoices from "layouts/transactions/components/Invoices";

const AdminDashboard = () => {
  const cookies = new Cookies();
  const [saveData, setSaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [paymentsHistoryData, setPaymentsHistoryData] = useState([]);

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { loading, handleGetCreditHistory } = useGetCreditHistory();
  const { handleGetPaymentHistory } = useGetPaymentHistory();
  const { loading: creditsGetLoading, handleGetAllProjects } = useGetAllProjects();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };
  const getAllProject = async () => {
    handleGetAllProjects({
      onSuccess: (res) => {
        setSaveData(res.data.results);
        setIsLoading(false);
      },
    });
  };

  const getCreditHistory = async () => {
    handleGetCreditHistory({ onSuccess: (res) => setCreditHistoryData(res.data.results) });
  };

  const getPaymentHistory = async () => {
    handleGetPaymentHistory({ onSuccess: (res) => setPaymentsHistoryData(res.data.results) });
  };

  useEffect(() => {
    getAllProject();
    getCreditHistory();
    getPaymentHistory();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <MDBox>
          {isLoading ? (
            <></>
          ) : (
            saveData.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Projects
                    setPage={setPage}
                    count={count}
                    page={page}
                    saveData={saveData}
                    setSaveData={setSaveData}
                    open={open}
                    setOpen={setOpen}
                    onSuccessPost={getAllProject}
                    isDashboard
                    isAdmin
                    handleSearchChange={() => {}}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Invoices isDashboard data={paymentsHistoryData} handleSearchPayment={() => {}} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Transactions
                    isDashboard
                    data={creditHistoryData}
                    handleSearchCredits={() => {}}
                  />
                </Grid>
              </Grid>
            )
          )}
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdminDashboard;

import React, { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";
import Cookies from "universal-cookie";

const UseUpdateUser = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };
  const handleUpdateUser = async ({ data, onSuccess = null, onError = null }) => {
    try {
      setLoading(true);
      const res = await axios.patch(baseUrl + apiV1 + `/users/${data.id}`, data, { headers });
      console.log({ res });
      if (res.status === StatusCodes.OK) {
        dispatch(setAlert({ message: "Account Verification Success", color: "success" }));
        onSuccess && onSuccess(res);
      } else {
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log({ e });
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleUpdateUser };
};

export default UseUpdateUser;

import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { setUser } from "reduxToolkit/user/userSlice";
import Cookies from "universal-cookie";
import { apiV1 } from "utils/constants";
import { baseUrl } from "utils/constants";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
const useLogin = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ email, password, onSuccess = null, onError = null }) => {
    try {
      console.log({ email, password });
      setLoading(true);
      const res = await axios.post(baseUrl + apiV1 + "/auth/login", {
        email: email,
        password: password,
      });
      console.log("response", res);
      if (res.data.statusCode === 200) {
        console.log("new user", "new user", res.data.token);
        cookies.set("token", res.data.token, {
          path: "/",
          // expires: new Date(Date.now() + res.data.expires * 1000),
        });
        cookies.set("role", res.data.user.role, {
          path: "/",
          // expires: new Date(Date.now() + res.data.expires * 1000),
        });

        dispatch(setAlert({ message: "Login Success", color: "success" }));
        dispatch(setUser(res.data.user));
        onSuccess && onSuccess(res.data.user.role);
        setLoading(false);
      } else {
        dispatch(
          setAlert({
            message: res?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log("error", e);
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return {
    loading,
    handleLogin,
  };
};

export default useLogin;

import React, { useEffect, useState } from "react";
import { dummyData } from "./utils";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Projects from "layouts/dashboard/components/Projects";
import Cookies from "universal-cookie";
import useGetUsers from "hook/useGetAllUsers";

const AdminUsers = () => {
  const [saveData, setSaveData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { handleGetUsesr } = useGetUsers();
  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
  };
  useEffect(() => {
    handleGetUsesr({
      limit: 10,
      page,
      search: searchQuery,
      onSuccess: (res) => {
        setSaveData(res.data.results?.reverse());
        setCount(res.data.totalPages);
      },
    });
  }, [page, searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <Projects
          dataType="users"
          setPage={setPage}
          count={count}
          page={page}
          saveData={saveData}
          setSaveData={setSaveData}
          handleSearchChange={handleSearchChange}
          open={open}
          setOpen={setOpen}
          onSuccessPost={() => console.log("first")}
          isAdmin
          title="Users"
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdminUsers;

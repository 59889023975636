import React, { useEffect, useState } from "react";
import ProfileComponent from "../../../profile/components/ProfileComponent";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { setUser } from "../../../../reduxToolkit/user/userSlice";
import useUpdateUser from "../../../../hook/useUpdateUser";

const DetailsForm = ({ user: userData }) => {
  const [user, setUser] = useState(userData);
  const { handleUpdateUser } = useUpdateUser();
  useEffect(() => {
    setUser(userData);
  }, [userData]);
  const onSubmit = async (data, setEditing) => {
    await handleUpdateUser({
      data,
      onSuccess: (res) => {
        setEditing(false);
        setUser(res.data);
      },
    });
  };

  return <ProfileComponent user={user} onSubmit={onSubmit} />;
};

export default DetailsForm;

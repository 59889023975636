import React, { useEffect, useState } from "react";
import Projects from "../../../dashboard/components/Projects";
import useGetAllProjects from "hook/useGetAllProjects";

const UserProject = ({ userId }) => {
  const [saveData, setSaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { loading: creditsGetLoading, handleGetAllProjects } = useGetAllProjects();

  const getAllProject = async () => {
    handleGetAllProjects({
      page,
      search: searchQuery,
      limit: 10,
      userId,
      onSuccess: (res) => {
        setSaveData(res.data.results);
        setIsLoading(false);
        setCount(res.data.totalPages);
      },
    });
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
  };

  useEffect(() => {
    getAllProject();
  }, [page, searchQuery]);

  return (
    // <Projects
    //   setPage={setPage}
    //   dataType={"project"}
    //   count={count}
    //   page={page}
    //   saveData={saveData}
    //   setSaveData={setSaveData}
    //   open={open}
    //   setOpen={setOpen}
    //   onSuccessPost={() => console.log("first")}
    //   handleSearchChange={() => {}}
    //   title="Project"
    // />
    <Projects
      handleSearchChange={handleSearchChange}
      setPage={setPage}
      count={count}
      page={page}
      saveData={saveData}
      setSaveData={setSaveData}
      open={open}
      setOpen={setOpen}
      onSuccessPost={getAllProject}
      isAdmin
    />
  );
};

export default UserProject;

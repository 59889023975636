import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import useGetPaymentHistory from "../../../../hook/useGetPaymentHistory";
import Invoices from "../../../transactions/components/Invoices";

const UserPayment = ({ userId }) => {
  const cookies = new Cookies();
  const [paymentsHistoryData, setPaymentsHistoryData] = useState([]);
  const [pagePayment, setPagePayment] = useState(1);
  const [searchPayment, setSearchPayment] = useState("");
  const [countPayment, setCountPayment] = useState(1);

  const { loading, handleGetPaymentHistory } = useGetPaymentHistory();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const getPaymentsHistory = async () => {
    await handleGetPaymentHistory({
      limit: 10,
      search: searchPayment,
      page: pagePayment,
      userId,
      onSuccess: (res) => {
        setCountPayment(res.data.totalPages);
        setPaymentsHistoryData(res.data.results);
      },
    });
  };

  const handleSearchPayment = (search) => {
    setSearchPayment(search);
    setPagePayment(1);
  };

  useEffect(() => {
    getPaymentsHistory();
  }, [searchPayment, pagePayment]);

  return (
    <Invoices
      data={paymentsHistoryData}
      handleSearchPayment={handleSearchPayment}
      page={pagePayment}
      setPage={setPagePayment}
      count={countPayment}
    />
  );
};
export default UserPayment;

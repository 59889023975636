import { TextField, TextareaAutosize } from "@mui/material";
import React from "react";

const TextArea = ({ answers, setAnswers }) => {
  return (
    <TextField
      value={answers}
      onChange={(e) => {
        setAnswers(e.target.value);
      }}
      multiline
      rows={8}
      variant="outlined"
      placeholder="Type your answer here"
      sx={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: "8px",
      }}
    />
  );
};

export default TextArea;

import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Cookies from "universal-cookie";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

function createData(id, companyname, pending, completed, drafted, total) {
  return {
    id,
    companyname,
    assigned: pending,
    pending,
    completed,
    drafted,
    total,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "companyname",
    numeric: false,
    disablePadding: true,
    label: "Company",
  },
  {
    id: "assigned",
    numeric: true,
    disablePadding: false,
    label: "Assigned",
  },
  {
    id: "completed",
    numeric: true,
    disablePadding: false,
    label: "Completed",
  },
  {
    id: "drafted",
    numeric: true,
    disablePadding: false,
    label: "Drafted",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{ display: "contents" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable({
  rows,
  page,
  rowsPerPage,
  order,
  orderBy,
  onRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCount,
  loading,
}) {
  return (
    <Box sx={{ width: "100%", paddingTop: "20px" }}>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: "1rem" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              rowCount={totalCount}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress sx={{ color: "#007bff" }} />
                  </TableCell>
                </TableRow>
              ) : rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.companyname}
                        </TableCell>
                        <TableCell align="left">{row.pending}</TableCell>
                        <TableCell align="left">{row.completed}</TableCell>
                        <TableCell align="left">{row.drafted}</TableCell>
                        <TableCell align="left">{row.total}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

const TaskStatusPage = () => {
  const cookies = new Cookies();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const compare = (a, b) => {
      console.log({ a: a[orderBy], b, orderBy });
      if (typeof a[orderBy] === "string") {
        if (order === "asc") {
          return a[orderBy]?.localeCompare(b[orderBy]);
        } else {
          return b[orderBy]?.localeCompare(a[orderBy]);
        }
      } else {
        if (order === "asc") {
          return a[orderBy] - b[orderBy];
        } else {
          return b[orderBy] - a[orderBy];
        }
      }
    };

    // Function to handle sorting and updating state
    const handleSort = () => {
      setRows((prevState) => [...prevState].sort(compare));
    };
    handleSort();
  }, [order, orderBy]);

  const fetchTaskCounts = async (page, rowsPerPage) => {
    try {
      setLoading(true);

      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(baseUrl + apiV1 + `/companies/getstatus/`, {
        headers,
        params: {
          page: page + 1,
        },
      });

      const { data } = response;
      if (typeof data === "object" && data !== null) {
        const { statusCounts, totalCount } = data;
        const newRows = Object.keys(statusCounts).map((companyName, index) => {
          const companyData = statusCounts[companyName];
          const { status, total } = companyData;
          return createData(
            index + 1,
            companyName,
            status.Pending,
            status.Completed,
            status.Drafted,
            total
          );
        });
        setRows(newRows);
        setTotalCount(totalCount);
        console.log("API Response Data:", data);
      } else {
        console.error("Invalid data format received from the API");
      }
    } catch (error) {
      console.error("Error fetching task counts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskCounts(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    fetchTaskCounts(page, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <EnhancedTable
        rows={rows}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalCount}
        loading={loading}
      />
    </DashboardLayout>
  );
};

export default TaskStatusPage;

import React, { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";

const useconfirmEmailOTP = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmEmailOTP = async ({ code, email, onSuccess = null, onError = null }) => {
    try {
      setLoading(true);
      const res = await axios.post(baseUrl + apiV1 + "/auth/confirm-email", {
        email: email,
        code: code,
      });
      if (res.status === StatusCodes.OK) {
        dispatch(setAlert({ message: "Account Verification Success", color: "success" }));
        onSuccess && onSuccess();
      } else {
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log("error", e);
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleConfirmEmailOTP };
};

export default useconfirmEmailOTP;

import React from "react";
import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import { useForm } from "react-hook-form";
import useForgotPassword from "../../../../../hook/useForgotPassword";
import useConfirmNewPassword from "../../../../../hook/useConfirmNewPassword";
import { useNavigate } from "react-router-dom";

const ConfirmNewPasswordFields = ({ page, setPage }) => {
  const navigate = useNavigate();
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleConfirmNewPassword, loading } = useConfirmNewPassword();
  const submit = (data) => {
    handleConfirmNewPassword({
      email: page,
      code: data.code,
      password: data.password,
      onSuccess: () => navigate("/authentication/sign-in"),
    });
  };
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Confirm New Password
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Please enter received code and new password
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={4}>
            <MDInput
              type="text"
              label="Code"
              variant="standard"
              fullWidth
              {...register("code", {
                required: "Code is required",
                pattern: {
                  value: /^\d{6}$/, // 6 digits
                  message: "Enter a valid 6-digit code",
                },
              })}
            />
            {errors?.code ? (
              <MDTypography variant="button" color="error">
                {errors?.code?.message}
              </MDTypography>
            ) : null}
          </MDBox>
          <MDBox mb={4}>
            <MDInput
              type="password"
              label="Password"
              variant="standard"
              fullWidth
              {...register("password", {
                required: "Password is required", // Add required validation
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                  message: `Contains at least 1 number.
                    Contains at least 1 special character.
                    Contains at least 1 uppercase letter.
                    Contains at least 1 lowercase letter.
                    Minimum of 8 characters in length.`,
                },
              })}
            />
            {errors?.password ? (
              <MDTypography variant="button" color="error">
                {errors?.password?.message}
              </MDTypography>
            ) : null}
          </MDBox>
          <MDBox mb={4}>
            <MDInput
              type="password"
              label="Confirm Password"
              variant="standard"
              fullWidth
              {...register("confirmPassword", {
                required: "Confitm Password is required",
                validate: (value) => value === getValues("password") || "Passwords do not match",
              })}
            />
            {errors?.confirmPassword ? (
              <MDTypography variant="button" color="error">
                {errors?.confirmPassword?.message}
              </MDTypography>
            ) : null}
          </MDBox>
          <MDBox mt={6} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit(submit)}>
              reset
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ConfirmNewPasswordFields;

// NotFound.js
import React from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Oops! Page not found.
      </Typography>
      <Button
        component={Link}
        to="/users"
        variant="contained"
        color="primary"
        sx={{
          marginTop: "20px", // Add margin top
          backgroundColor: "red", // Change background color
          "&:hover": {
            backgroundColor: "darkred", // Change background color on hover
          },
        }}
      >
        <Typography style={{ color: "#fff", fontSize: 14 }}>Go Back to Home</Typography>
      </Button>
    </div>
  );
};

export default NotFound;

import { Card, Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import coin from "../../assets/images/coin.png";

import axios from "axios";
import { apiV1 } from "utils/constants";
import { baseUrl } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { setUser } from "reduxToolkit/user/userSlice";
const cookies = new Cookies();

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payment = () => {
  const user = useSelector((store) => store.user.data);
  const [data, setData] = useState({ ...user });
  const [amount, setAmount] = useState(100);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { amount: 100 }, mode: "onChange" });
  const submit = (data) => {
    displayRazorpay(data);
  };

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  async function displayRazorpay(payload) {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      dispatch(
        setAlert({ message: "Razorpay SDK failed to load. Are you online?", color: "error" })
      );
      // alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      `${baseUrl}${apiV1}/payment/orders`,
      {
        amount: payload.amount,
        currency: "INR",
      },
      { headers }
    );

    if (!result) {
      dispatch(setAlert({ message: "Server error. Are you online?", color: "error" }));
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_test_Tj9Ik8I5NCUqJb",
      amount: amount.toString(),
      currency: currency,
      name: user.data?.name,
      description: "Test Transaction",
      image: { logo: coin },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(`${baseUrl}${apiV1}/payment/success`, data, { headers });
        dispatch(setAlert({ message: "Payment successful!", color: "success" }));
        dispatch(setUser({ ...user, credits: result?.data?.balance }));
      },
      prefill: {
        name: user?.data?.name,
        email: user?.data?.email,
        contact: user?.data?.mobile_number,
      },
      notes: {
        address: user?.data?.billingAddress,
      },
      theme: {
        color: "#42424a",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ marginTop: "1rem" }}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="flex-start"
              p={3}
            >
              <MDBox mb={-1}>
                <MDTypography variant="h4" gutterBottom>
                  Credits Info
                </MDTypography>
              </MDBox>
              <MDBox mt={2} mb={2}>
                <MDInput
                  type="text"
                  label="Credits"
                  name="amount"
                  fullWidth
                  {...register("amount", {
                    onChange: (e) => setAmount(e.target.value),
                    required: "Amount is required",
                    pattern: {
                      value: /^(?:[1-9][0-9]{1,2}|1000)$/,
                      message: "Invalid Amount. Please enter a number between 10 and 1000.",
                    },
                  })}
                />
                {errors?.amount ? (
                  <MDTypography variant="button" color="error">
                    {errors?.amount?.message}
                  </MDTypography>
                ) : null}
              </MDBox>
              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Total Amount - {parseInt(amount) || 0}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection={"row"}
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <MDBox mb={-1}>
                <MDTypography variant="h4" gutterBottom>
                  Billing Info
                </MDTypography>
              </MDBox>
              <MDBox mb={-1}>
                <MDButton variant="outlined" color="info" onClick={() => navigate("/profile")}>
                  Change
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="flex-start"
              p={3}
            >
              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Full Name
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {data.name}
                </MDTypography>
              </MDBox>

              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Email
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {data.email}
                </MDTypography>
              </MDBox>

              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Mobile No
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {data.mobile_number ? data.mobile_number : "-"}
                </MDTypography>
              </MDBox>

              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  GST Number
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {data.gstNo ? data.gstNo : "-"}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="flex-start"
              p={3}
            >
              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Billing Address
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {/* {data.billing?.address ? data.billing?.address : "-"} */}
                  {`${data.billingAddress?.street ? data.billingAddress?.street : "-"} - ${
                    data.billingAddress?.city ? data.billingAddress?.city : ""
                  }`}
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {/* {data?.billing?.address ? data?.billing?.address : "-"} */}
                  {`${data?.billingAddress?.state ? data?.billingAddress?.state : ""} - ${
                    data?.billingAddress?.country ? data?.billingAddress?.country : ""
                  } - ${data?.billingAddress?.postalCode ? data?.billingAddress?.postalCode : ""}`}
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <Checkbox
                  defaultChecked={data.sameAsBilling}
                  checked={data.sameAsBilling}
                  sx={{ padding: 0 }}
                  disabled
                />
                <MDTypography variant="button" fontWeight="regular" color="text" ml={1}>
                  Shipping Address same as Billing Address
                </MDTypography>
              </MDBox>
              <MDBox mb={-1}>
                <MDTypography variant="h6" gutterBottom>
                  Shipping Address
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {/* {data?.shipping?.address ? data?.shipping?.address : "-"} */}

                  {`${data.shippingAddress?.street ? data.shippingAddress?.street : "-"} - ${
                    data.shippingAddress?.city ? data.shippingAddress?.city : ""
                  }`}
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {/* {data?.shipping?.address ? data?.shipping?.address : "-"} */}
                  {`${data?.shippingAddress?.state ? data?.shippingAddress?.state : ""} - ${
                    data?.shippingAddress?.country ? data?.shippingAddress?.country : ""
                  } - ${
                    data?.shippingAddress?.postalCode ? data?.shippingAddress?.postalCode : ""
                  }`}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              flexDirection={"row"}
              justifyContent="flex-end"
              alignItems="center"
              p={3}
            >
              <MDBox mb={-1}>
                <MDButton variant="outlined" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </MDButton>
              </MDBox>
              <MDBox mb={-1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  onClick={handleSubmit(submit)}
                  sx={{ marginLeft: 2 }}
                >
                  Confirm
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default Payment;

import React, { useState } from "react";
import { StatusCodes, ReasonPhrases } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";
import Cookies from "universal-cookie";

const useGetAllProjects = () => {
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const handleGetAllProjects = async ({
    limit = 5,
    page = 1,
    search = "",
    userId,
    onSuccess = null,
    onError = null,
  }) => {
    try {
      setLoading(true);
      const res = await axios.get(
        baseUrl +
          apiV1 +
          `/project?projectBy=name,status,createdAt,updatedAt,validation_error,user&sortBy=createdAt:desc&page=${page}&limit=${limit}${
            search !== "" ? `&query=${search}` : ""
          }${userId ? `&userId=${userId}` : ""}`,
        { headers }
      );
      //&query=${search}
      if (res.status === StatusCodes.OK) {
        onSuccess && onSuccess(res);
      } else {
        console.log("project else");
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log("project catch", e);
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleGetAllProjects };
};

export default useGetAllProjects;

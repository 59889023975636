/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

import { LoopPlugin, TemplateHandler, TextPlugin } from "easy-template-x";

const digitsMap = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
};
function convertNumberToString(number) {
  const numberAsString = number.toString();
  let result = "";

  for (let i = 0; i < numberAsString.length; i++) {
    const digit = parseInt(numberAsString[i], 10);
    result += digitsMap[digit];
  }

  return result;
}

function extractIndex(array, searchString) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].toLowerCase().includes(searchString.toLowerCase())) {
      return i;
    }
  }
  return -1;
}

async function generateFile(data) {
  const response = await fetch("template.docx");
  const templateFile = await response.blob();

  // 2. process the template

  const sampledata = {};

  data?.tasks?.forEach((item) => {
    console.log({ itemsssss: item });
    if (item.answer && item.question) {
      if (item.question.questionType === "text") {
        sampledata[item.question.key.toString()] = item.answer.answerText;
      } else {
        if (item.question.rows?.length > 0) {
          sampledata[convertNumberToString(item.question.key)] = {};
          item.answer?.answerText?.forEach((ans, index) => {
            if (ans) {
              const resultArray = Object.values(ans).flatMap((value) => {
                if (Array.isArray(value)) {
                  return value;
                } else {
                  return [value];
                }
              });
              resultArray.forEach((ra, idx) => {
                sampledata[convertNumberToString(item.question.key)][
                  `${digitsMap[index + 1]}${digitsMap[idx + 1]}`
                ] = ra;
              });
            }
          });
        } else {
          sampledata[item.question.key?.toString()] = [];
          item.answer?.answerText?.forEach((ans, index) => {
            if (ans) {
              let tempobj = {};
              const resultArray = Object.entries(ans).map((value) => {
                console.log({ first: extractIndex(item.question?.columns, value[0]) + 1, value });
                tempobj[extractIndex(item.question?.columns, value[0]) + 1] = value[1];
                return tempobj;
              });
              console.log({ resultArray });
              resultArray.forEach((ra, idx) => {
                sampledata[item.question.key?.toString()][index] = ra;
              });
            }
          });
        }
      }
    }
  });

  console.log({ sampledata });

  const handler = new TemplateHandler({
    scopeDataResolver: createResolver({
      requiredPrefix: "%",
    }),
  });
  const tags = await handler.parseTags(templateFile);
  const doc = await handler.process(templateFile, sampledata);
  saveFile("example.docx", doc);
}
// 3. save output

function saveFile(filename, blob) {
  const blobUrl = URL.createObjectURL(blob);

  // create temp link element
  let link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";
  link.href = blobUrl;

  document.body.appendChild(link);
  link.click();
}

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import filterIcon

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Tasks/data";
import MDButton from "components/MDButton";
import AddFile from "./AddFile";
import ViewLogs from "./viewLogs";
import userData from "./data/userData";
import { useLocation, useNavigate } from "react-router-dom";
import projectsList from "./data/projectsList";
import AddTask from "./AddTask";
import MultiSelectTable from "./MultiSelectTable";
import Cookies from "universal-cookie";
import AddAnswer from "../Fields/AddAnswer";
import { createResolver } from "easy-template-x-angular-expressions";
import sectionData from "layouts/dashboard/components/Tasks/data/sectionData";
import partsData from "layouts/dashboard/components/Tasks/data/partData";
import UnassignTask from "./UnassignTask";
import Addmainfile from "./Addmainfile";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

function Tasks({
  saveData,
  handleSearchChange,
  onSuccessPost,
  title = "Tasks",
  count,
  setPage,
  page,
  setSaveData,
}) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [task, setTask] = useState("");
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [customquestion, setCustomquestion] = useState(null);

  const cookies = new Cookies();
  const role = cookies.get("role");
  const [openErrorLogs, setOpenErrorLogs] = useState(false);
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("");
  const onNameClick = (id) => {
    navigate(`/users/${id}`);
  };
  const router = useLocation();
  const [menu, setMenu] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [partsDataArray, setPartsDataArray] = useState({ columns: [], rows: [] });
  const [sectionDataArray, setSectionDataArray] = useState({ columns: [], rows: [] });
  const [taskDataArray, setTaskDataArray] = useState({ columns: [], rows: [] });
  const [filteredData, setFilteredData] = useState([]);

  // const { columns, rows } = data(saveData, setSelectedCompany);
  // const sectionDataArray = sectionData(saveData, setSelectedSection);
  // const partsDataArray = partsData(saveData, setSelectedPart);

  useEffect(() => {
    setTaskDataArray(data(saveData, setSelectedCompany));
  }, [saveData]);

  useEffect(() => {
    if (selectedCompany) {
      setSectionDataArray(
        sectionData(
          saveData.filter((item) => item._id === selectedCompany)?.[0]?.tasks,
          setSelectedSection
        )
      );
    }
  }, [selectedCompany, saveData]);

  useEffect(() => {
    if (selectedSection && selectedCompany) {
      setPartsDataArray(
        partsData(
          saveData.filter((item) => item._id === selectedCompany)?.[0]?.tasks,
          selectedSection,
          setSelectedPart
        )
      );
    }
  }, [selectedSection, selectedCompany, saveData]);

  useEffect(() => {
    if (selectedCompany && selectedSection && selectedPart) {
      setFilteredData(
        saveData
          .filter((item) => item._id === selectedCompany)?.[0]
          ?.tasks?.filter(
            (item) =>
              item?.question?.section === selectedSection && item?.question?.part === selectedPart
          )
      );
    } else {
      setFilteredData([]);
    }
  }, [selectedSection, selectedPart, selectedCompany, saveData]);

  const handleSearch = (searchText) => {
    handleSearchChange(searchText);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  // //for custom table
  // useEffect(() => {
  //   const headers = {
  //     Authorization: `Bearer ${cookies.get("token")}`,
  //     "Content-Type": "application/json",
  //   };

  //   const fetchTasks = async () => {
  //     const res = await axios.get(
  //       baseUrl + apiV1 + `/custom?type=${task?.question?.parentQuestion}`,
  //       {
  //         headers,
  //       }
  //     );

  //     setCustomquestion(res.data?.tasks[0]);
  //     console.log("firstfirst", res);
  //   };

  //   if (task?.question?.parentQuestion) {
  //     fetchTasks();
  //   }
  // }, [task]);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex" alignItems="center" sx={{ width: "100%" }}>
          {selectedCompany && (
            <Button
              style={{
                padding: "0px",
                marginRight: 10,
                minWidth: "auto",
                margin: "10",
                textTransform: "none",
                fontWeight: 400,
                borderRadius: "12",
              }}
              variant="text"
              onClick={() => {
                if (selectedPart) {
                  setSelectedPart(null);
                } else if (selectedSection) {
                  setSelectedSection(null);
                } else if (selectedCompany) {
                  setSelectedCompany(null);
                }
              }}
              p={0}
            >
              <ArrowBackIcon
                sx={{
                  fontSize: "2rem",
                  color: "black",
                  marginRight: 0,
                }}
              />
            </Button>
          )}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MDTypography variant="h6" gutterBottom>
              {title}
            </MDTypography>
            {selectedCompany && (
              <MDButton
                onClick={() => {
                  generateFile(saveData.filter((item) => item._id === selectedCompany)?.[0]);
                }}
                variant="contained"
                // variant="h6"
                // gutterBottom
              >
                Download word
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        {selectedCompany ? (
          <>
            {selectedSection ? (
              <>
                {selectedPart ? (
                  <MultiSelectTable
                    saveData={filteredData}
                    role={role}
                    setTask={setTask}
                    setSelectedQuestion={setSelectedQuestion}
                    setIsUnassignModalOpen={setIsUnassignModalOpen}
                    customquestion={customquestion}
                    setCustomquestion={setCustomquestion}
                    task={task}
                    selectedQuestion={selectedQuestion}
                  />
                ) : (
                  <DataTable
                    count={count}
                    pageNumber={page}
                    setPage={setPage}
                    table={{ columns: partsDataArray.columns, rows: partsDataArray.rows }}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                    entriesPerPage={false}
                    isDashboard={false}
                  />
                )}
              </>
            ) : (
              <DataTable
                count={count}
                pageNumber={page}
                setPage={setPage}
                table={{ columns: sectionDataArray.columns, rows: sectionDataArray.rows }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
                isDashboard={false}
              />
            )}
          </>
        ) : (
          <DataTable
            count={count}
            pageNumber={page}
            setPage={setPage}
            table={{ columns: taskDataArray.columns, rows: taskDataArray.rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            isDashboard={false}
          />
        )}
      </MDBox>

      {customquestion ? (
        <Addmainfile
          saveData={saveData}
          customquestion={customquestion}
          setcustomquestion={setCustomquestion}
          maindata={saveData}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          task={task}
          setTask={setTask}
          updateAnswer={(data) => {
            const tempData = [...saveData];
            const taskIndex = tempData.findIndex((item) => item._id === selectedCompany);
            const questionIndex = tempData[taskIndex]?.tasks.findIndex(
              (item) => item._id === data.id
            );

            const tempTasks = [...tempData[taskIndex]?.tasks];

            tempTasks[questionIndex] = { ...data, _id: data.id };
            tempData[taskIndex].tasks = tempTasks;

            console.log({ tempData });

            setSaveData(tempData);
          }}
        />
      ) : (
        <AddAnswer
          setCustomquestion={setCustomquestion}
          selectedQuestion={selectedQuestion}
          // selectedQuestion={false}
          setSelectedQuestion={setSelectedQuestion}
          task={task}
          setTask={setTask}
          updateAnswer={(data) => {
            const tempData = [...saveData];
            const taskIndex = tempData.findIndex((item) => item._id === selectedCompany);
            const questionIndex = tempData[taskIndex]?.tasks.findIndex(
              (item) => item._id === data.id
            );

            const tempTasks = [...tempData[taskIndex]?.tasks];

            tempTasks[questionIndex] = { ...data, _id: data.id };
            tempData[taskIndex].tasks = tempTasks;

            console.log({ tempData });

            setSaveData(tempData);
          }}
        />
      )}
      <UnassignTask
        isUnassignModalOpen={isUnassignModalOpen}
        setIsUnassignModalOpen={setIsUnassignModalOpen}
        deleteAnswer={(data) => {
          const tempData = [...saveData];
          const taskIndex = tempData.findIndex((item) => item._id === selectedCompany);
          const questionIndex = tempData[taskIndex]?.tasks.findIndex(
            (item) => item._id === data._id
          );

          tempData[taskIndex].tasks.splice(questionIndex, 1);

          console.log({ tempData });

          setSaveData(tempData);
        }}
      />
    </Card>
  );
}

export default Tasks;

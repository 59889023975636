/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 Coded by www.creative-tim.com
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.
 Once you add a new route on this file it will be visible automatically on
 the Sidenav.
 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import AuthValidator from "layouts/AuthValidator";
import Profile from "layouts/profile";
import AdminDashboard from "./layouts/Admin/AdminDashboard";
import AdminUsers from "./layouts/Admin/AdminUsers";
import User from "./layouts/Admin/User";
import ResetPassword from "./layouts/authentication/reset-password/cover";
import AdminFields from "layouts/Admin/AdminFields";
import AdminTasks from "layouts/Admin/AdminTasks";
import NotFound from "layouts/notfound";
import QuestionPage from "./layouts/questionsList/QuestionPage";

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/not-found",
    // component: isTokenValid() ? <Dashboard /> : <Redirect />,
    component: <NotFound />,
  },
  {
    type: "collapse",
    name: "Modules",
    key: "modules",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/modules",
    component: (
      <AuthValidator>
        <AdminFields />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tasks",
    component: (
      <AuthValidator>
        <AdminTasks />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/forgot-password",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/project",
    // component: isTokenValid() ? <Dashboard /> : <Redirect />,
    component: (
      <AuthValidator>
        <AdminDashboard />
      </AuthValidator>
    ),
  },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: (
      <AuthValidator>
        <AdminUsers />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users/:id",
    component: (
      <AuthValidator>
        <User />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: (
      <AuthValidator>
        <Profile />
      </AuthValidator>
    ),
  },
  {
    type: "collapse",
    name: "Question",
    key: "question",
    icon: <Icon fontSize="small">question</Icon>,
    route: "/question",
    component: (
      <AuthValidator>
        <QuestionPage />
      </AuthValidator>
    ),
  },
];

export default routes;

// ConfirmDialog.js
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "universal-cookie";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

export default function ConfirmDialog({
  isOpen,
  onClose,
  onAgree,
  userId,
  name,
  handleCloseConfirmDialog,
  onDeleteSuccess,
  saveData,
  setSaveData,
}) {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const cookies = new Cookies();

      const response = await fetch(baseUrl + apiV1 + `/users/${userId}`, {
        method: "DELETE",

        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const updatedSaveData = saveData.filter((user) => user.id !== userId);
        setSaveData(updatedSaveData);

        onClose();
        dispatch(setAlert({ message: "User Deleted Successfully", color: "success" }));
        onDeleteSuccess({ userId, name });
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="modal-dialog modal-confirm">
        <div
          className="modal-content"
          style={{
            padding: "20px",
            borderRadius: "5px",
            border: "none",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          <div className="modal-header flex-column">
            <div
              className="icon-box"
              style={{
                width: "80px",
                height: "80px",
                margin: "0 auto",
                borderRadius: "50%",
                zIndex: "9",
                textAlign: "center",
                border: "3px solid #f15e5e",
              }}
            >
              <i
                className="material-icons"
                style={{ color: "#f15e5e", fontSize: "46px", marginTop: "13px" }}
              >
                &#xE5CD;
              </i>
            </div>
            <h4
              className="modal-title w-100"
              style={{ textAlign: "center", fontSize: "26px", margin: "30px 0 -10px" }}
            >
              Are you sure you want to remove {name} ?
            </h4>
          </div>
          <div className="modal-body" style={{ color: "#999", margin: "10px" }}>
            <p>Do you really want to delete these records? This process cannot be undone.</p>
          </div>
          <div
            className="modal-footer justify-content-center"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "5px",
              fontSize: "13px",
              padding: "10px 15px 25px",
            }}
          >
            <Button
              style={{
                borderRadius: 5,
                backgroundColor: "#a8a8a8",
                color: "black",
                fontSize: "12px",
                marginRight: "5px",
              }}
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: 5,
                backgroundColor: "#ee3535",
                color: "white",
                fontSize: "12px",
                marginLeft: "5px",
              }}
              variant="contained"
              onClick={handleDelete}
              endIcon={<DeleteIcon style={{ fontSize: "10px" }} />}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
// import { dummyData } from ".";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Projects from "layouts/dashboard/components/Projects";
import Fields from "layouts/dashboard/components/Fields";
import Cookies from "universal-cookie";
import useGetAllQuestions from "hook/useGetAllQuestions";

const principlesOrder = {
  "Section A": 0,
  "Section B": 1,
  "Principle 1": 2,
  "Principle 2": 3,
  "Principle 3": 4,
  "Principle 4": 5,
  "Principle 5": 6,
  "Principle 6": 7,
  "Principle 7": 8,
  "Principle 8": 9,
  "Principle 9": 10,
};

const AdminFields = () => {
  const [saveData, setSaveData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const { handleGetQuestions } = useGetAllQuestions();
  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
  };

  useEffect(() => {
    handleGetQuestions({
      limit: 10000,
      page,
      search: searchQuery,
      onSuccess: (res) => {
        console.log(res.data);
        setSaveData(
          res.data.results?.sort((a, b) => {
            const aKey = a._id.split(":")[0].trim();
            const bKey = b._id.split(":")[0].trim();
            return principlesOrder[aKey] - principlesOrder[bKey];
          })
        );
        setCount(res.data.totalPages);
      },
    });

    // setSaveData([...saveData, questionTable]);
  }, [page, searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <Fields
          dataType="users"
          setPage={setPage}
          count={count}
          page={page}
          saveData={saveData}
          setSaveData={setSaveData}
          handleSearchChange={handleSearchChange}
          open={open}
          setOpen={setOpen}
          onSuccessPost={() => console.log("first")}
          isAdmin
          title="Modules"
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdminFields;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDTypography from "components/MDTypography";
import logoXD from "assets/images/small-logos/logo-xd.svg";
import MDButton from "components/MDButton";
import { Avatar, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { apiV1 } from "utils/constants";
import { baseUrl } from "utils/constants";
import { useSelector } from "react-redux";
import { useState } from "react";
import AddTask from "../AddTask";

export default function data(list, setSelectedSection) {
  const user = useSelector((store) => store.user);
  const listDumed = ["questin 1", "question 2"];
  const userName = ["Amar", "Suraj", "Yogesh"];
  const cookies = new Cookies();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };
  const [open, setOpen] = useState(false);
  const getPresignedURL = async (projectId, type) => {
    const res = await axios.get(baseUrl + apiV1 + `/project/${projectId}/${type}`, { headers });
    if (res.data.url) {
      window.open(res.data.url, "_blank");
    }
  };
  const avatars = (members) =>
    members.map(([image, name]) => (
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Sections", accessor: "sections" },
    // { Header: "Parts", accessor: "email", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return {
    columns,
    rows: list.map((item) => {
      return {
        sections: <div>{item._id}</div>,
        // email: (
        //   <MDBox
        //     sx={{
        //       display: "flex",
        //       flexDirection: "row",
        //       gap: 1,
        //     }}
        //   >
        //     {userName.map((user) => (
        //       <Tooltip title={user}>
        //         <Avatar
        //           sx={{
        //             bgcolor: "#1976d2",
        //             width: 25,
        //             height: 25,
        //             fontWeight: 400,
        //             padding: 1,
        //             fontSize: "16px",
        //           }}
        //         >
        //           {user.charAt(0)}
        //         </Avatar>
        //       </Tooltip>
        //     ))}
        //   </MDBox>
        // ),
        // action: (
        //   <MDBox
        //     width="16rem"
        //     textAlign="left"
        //     style={{ gap: "12px", display: "flex", justifyContent: "center" }}
        //   >
        //     <MDButton
        //       variant="gradient"
        //       color="info"
        //       onClick={() => setOpen(true)}
        //       style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
        //     >
        //       Assign a Question
        //     </MDButton>
        //     <AddTask
        //       open={open}
        //       setOpen={setOpen}
        //       // saveData={saveData}
        //       // setSaveData={setSaveData}
        //       // onSuccessPost={onSuccessPost}
        //     />
        //     {/* <Tooltip title="Edit">
        //       <IconButton
        //         // className={classes.actionIcons}
        //         style={{}}
        //         onClick={() => console.log("Edit clicked")}
        //       >
        //         <EditIcon />
        //       </IconButton>
        //     </Tooltip>
        //     <Tooltip title="Delete">
        //       <IconButton
        //         // className={classes.actionIcons}
        //         onClick={() => console.log("Delete clicked")}
        //       >
        //         <DeleteIcon />
        //       </IconButton>
        //     </Tooltip> */}
        //   </MDBox>
        // ),
        action: (
          <MDBox
            onClick={() => {
              setSelectedSection(item.parts);
            }}
          >
            <Typography style={{ color: "blue", fontSize: 14, cursor: "pointer" }}>
              View parts
            </Typography>
          </MDBox>
        ),
      };
    }),
  };
}

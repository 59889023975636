import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Invoices from "layouts/transactions/components/Invoices";
import Transactions from "layouts/transactions/components/Transactions";
import { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import useGetCreditHistory from "../../hook/useGetCreditHistory";
import useGetPaymentHistory from "../../hook/useGetPaymentHistory";

function TransactionsTable() {
  const cookies = new Cookies();
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [paymentsHistoryData, setPaymentsHistoryData] = useState([]);

  const [pageCredits, setPageCredits] = useState(1);
  const [searchCredits, setSearchCredits] = useState("");

  const [pagePayment, setPagePayment] = useState(1);
  const [searchPayment, setSearchPayment] = useState("");

  const [countCredits, setCountCredits] = useState(1);
  const [countPayment, setCountPayment] = useState(1);

  const { loading: creditsGetLoading, handleGetCreditHistory } = useGetCreditHistory();
  const { loading, handleGetPaymentHistory } = useGetPaymentHistory();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const getCreditHistory = async () => {
    handleGetCreditHistory({
      limit: 10,
      search: searchCredits,
      page: pageCredits,
      onSuccess: (res) => {
        setCreditHistoryData(res.data.results);
        setCountCredits(res.data.totalPages);
      },
    });
  };

  const getPaymentsHistory = async () => {
    handleGetPaymentHistory({
      limit: 10,
      page: pagePayment,
      search: searchPayment,
      onSuccess: (res) => {
        setPaymentsHistoryData(res.data.results);
        setCountPayment(res.data.totalPages);
      },
    });
  };
  const handleSearchCredits = (search) => {
    setSearchCredits(search);
    setPageCredits(1);
  };
  const handleSearchPayment = (search) => {
    setSearchPayment(search);
    setPagePayment(1);
  };
  useEffect(() => {
    getCreditHistory();
  }, [pageCredits, searchCredits]);

  useEffect(() => {
    getPaymentsHistory();
  }, [pagePayment, searchPayment]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Transactions
                data={creditHistoryData}
                handleSearchCredits={handleSearchCredits}
                page={pageCredits}
                setPage={setPageCredits}
                count={countCredits}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Invoices
                data={paymentsHistoryData}
                handleSearchPayment={handleSearchPayment}
                page={pagePayment}
                setPage={setPagePayment}
                count={countPayment}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TransactionsTable;

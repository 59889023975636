import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import useGetCreditHistory from "../../../../hook/useGetCreditHistory";
import Transactions from "../../../transactions/components/Transactions";

const UserCredits = ({ userId }) => {
  const cookies = new Cookies();
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [paymentsHistoryData, setPaymentsHistoryData] = useState([]);

  const [pageCredits, setPageCredits] = useState(1);
  const [searchCredits, setSearchCredits] = useState("");
  const [countCredits, setCountCredits] = useState(1);

  const { loading: creditsGetLoading, handleGetCreditHistory } = useGetCreditHistory();
  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const getCreditHistory = async () => {
    await handleGetCreditHistory({
      limit: 10,
      search: searchCredits,
      page: pageCredits,
      userId,
      limit: 10,
      onSuccess: (res) => {
        setCreditHistoryData(res.data.results);
        setCountCredits(res.data.totalPages);
      },
    });
  };

  const handleSearchCredits = (search) => {
    setSearchCredits(search);
    setPageCredits(1);
  };

  useEffect(() => {
    getCreditHistory();
  }, [pageCredits, searchCredits]);

  return (
    <Transactions
      data={creditHistoryData}
      handleSearchCredits={handleSearchCredits}
      page={pageCredits}
      setPage={setPageCredits}
      count={countCredits}
    />
  );
};
export default UserCredits;

import React from "react";
import MDPagination from "../../components/MDPagination";
import MDBox from "../../components/MDBox";
import { Pagination } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import MDInput from "../../components/MDInput";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";

const CustomePagination = ({ count, page, setPage }) => {
  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      p={2}
    >
      <Pagination count={count} color="info" page={page} onChange={(e, page) => setPage(page)} />
    </MDBox>
  );
};

export default CustomePagination;

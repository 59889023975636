import React, { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import axios from "axios";

const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmNewPassword = async ({
    email,
    code,
    password,
    onSuccess = null,
    onError = null,
  }) => {
    try {
      setLoading(true);
      const res = await axios.post(baseUrl + apiV1 + "/auth/confirm-new-password", {
        email,
        code,
        password,
      });
      if (res.status === StatusCodes.OK) {
        dispatch(setAlert({ message: res.data.message, color: "success" }));
        onSuccess && onSuccess();
      } else {
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    }
  };

  return { loading, handleConfirmNewPassword };
};

export default useForgotPassword;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material componentsimport React from 'react';

import React from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { setUser } from "reduxToolkit/user/userSlice";
import ProfileComponent from "./components/ProfileComponent";
import UseUpdateUser from "../../hook/useUpdateUser";

function Profile() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.data);
  const { handleUpdateUser } = UseUpdateUser();
  const submitApi = async (data, setEditing) => {
    await handleUpdateUser({
      data,
      onSuccess: (res) => {
        setEditing(false);
        dispatch(setUser(res.data));
      },
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ProfileComponent user={user} onSubmit={submitApi} />
    </DashboardLayout>
  );
}

export default Profile;

import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Button, Tooltip } from "@mui/material";
// import {  } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// Import the TextField component from Material-UI
import { TextField } from "@mui/material";

const filterdata = async (data) => {
  // console.log("tttt", data);
};

// @ts-ignore
export default function MultiSelectTable({ saveData, setSelectedRows, setSelectedQuestion }) {
  const navigate = useNavigate();

  const handleQuestionClick = (item) => {
    try {
      // const selectedQuestion = saveData[0].details.find((question) => question.questionText === questionText);
      // onQuestionSelect(selectedQuestion);
      // const newUrl = `/question?text=${encodeURIComponent(questionText)}`;
      // console.log('Button clicked. Navigating to:', newUrl);
      // navigate(newUrl);
      setSelectedQuestion(item);
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };

  // const rows = [
  //   {
  //     id: 90,
  //     questions: "Question 1",
  //     firstName: "Jon",
  //     age: 5,
  //   },
  //   {
  //     id: 2090,
  //     questions: "Question 2",
  //     firstName: "Cersei",
  //     renderCell: (
  //       <>
  //         <MDBox
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             gap: 1,
  //           }}
  //         >
  //           {userName.map((user) => (
  //             <Tooltip title={user}>
  //               <Avatar
  //                 sx={{
  //                   bgcolor: "#1976d2",
  //                   width: 25,
  //                   height: 25,
  //                   fontWeight: 400,
  //                   padding: 1,
  //                   fontSize: "16px",
  //                 }}
  //               >
  //                 {user.charAt(0)}
  //               </Avatar>
  //             </Tooltip>
  //           ))}
  //         </MDBox>
  //       </>
  //     ),
  //   },

  //   { id: 1, questions: "Stark", firstName: "Jon", age: 25 },
  //   { id: 2, questions: "Lannister", firstName: "Tyrion", age: 40 },
  //   { id: 3, questions: "Baratheon", firstName: "Robert", age: 38 },
  //   { id: 4, questions: "Targaryen", firstName: "Viserys", age: 28 },
  //   { id: 5, questions: "Greyjoy", firstName: "Theon", age: 30 },
  //   { id: 6, questions: "Martell", firstName: "Ellaria", age: 35 },
  //   { id: 7, questions: "Tyrell", firstName: "Margaery", age: 29 },
  //   { id: 8, questions: "Bolton", firstName: "Ramsay", age: 32 },
  //   { id: 9, questions: "Clegane", firstName: "Sandor", age: 42 },
  //   { id: 10, questions: "Tully", firstName: "Catelyn", age: 36 },
  //   { id: 11, questions: "Arryn", firstName: "Lysa", age: 33 },
  //   { id: 12, questions: "Mormont", firstName: "Jorah", age: 45 },
  //   { id: 13, questions: "Hodor", firstName: null, age: 50 },
  //   { id: 14, questions: "Tarly", firstName: "Samwell", age: 28 },
  //   { id: 15, questions: "Redwyne", firstName: "Olenna", age: 70 },
  //   { id: 16, questions: "Seaworth", firstName: "Davos", age: 50 },
  //   { id: 17, questions: "Selmy", firstName: "Barristan", age: 65 },
  //   { id: 18, questions: "Cerwyn", firstName: "Jon", age: 30 },
  //   { id: 19, questions: "Forrester", firstName: "Rodrik", age: 40 },
  //   { id: 20, questions: "Umber", firstName: "Greatjon", age: 45 },
  //   { id: 21, questions: "Manderly", firstName: "Wyman", age: 55 },
  //   { id: 22, questions: "Baelish", firstName: "Petyr", age: 45 },
  //   { id: 23, questions: "Mallister", firstName: "Jason", age: 38 },
  //   { id: 24, questions: "Karstark", firstName: "Harald", age: 42 },
  //   { id: 25, questions: "Connington", firstName: "Jon", age: 40 },
  //   { id: 26, questions: "Florent", firstName: "Alester", age: 50 },
  //   { id: 27, questions: "Blackwood", firstName: "Tytos", age: 35 },
  //   { id: 28, questions: "Royce", firstName: "Yohn", age: 60 },
  //   { id: 29, questions: "Stokeworth", firstName: "Bronn", age: 35 },
  //   { id: 30, questions: "Crakehall", firstName: "Gregor", age: 45 },
  //   { id: 31, questions: "Hightower", firstName: "Leyton", age: 65 },
  //   { id: 32, questions: "Tallhart", firstName: "Helman", age: 40 },
  //   { id: 33, questions: "Reed", firstName: "Meera", age: 25 },
  //   { id: 34, questions: "Karstark", firstName: "Alys", age: 22 },
  //   { id: 35, questions: "Whent", firstName: "Minisa", age: 60 },
  //   { id: 36, questions: "Hornwood", firstName: "Halys", age: 50 },
  //   { id: 37, questions: "Tarth", firstName: "Brienne", age: 32 },
  //   { id: 38, questions: "Mormont", firstName: "Maege", age: 55 },
  //   { id: 39, questions: "Cassel", firstName: "Rodrik", age: 45 },
  //   { id: 40, questions: "Karstark", firstName: "Torrhen", age: 28 },

  //   //   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  //   //   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  //   //   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  //   //   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  // ];

  const columns = [
    {
      field: "questiontext",
      headerName: "Question",
      minWidth: 700,
      renderCell: (params) => {
        // return params.row.questionText;

        return (
          <>
            <Button
              onClick={() => {
                handleQuestionClick(params.row);
              }}
              color="primary"
              sx={{
                display: "block",
              }}
            >
              {params.row.questionText}
            </Button>
          </>
        );
      },
    },
    {
      field: "questiontype",
      headerName: "Question Type",
      renderCell: (params) => {
        return <p style={{ textTransform: "capitalize" }}>{params.row.questionType}</p>;
      },
    },

    // {
    //   field: "assignedto",
    //   headerName: "Assigned To",
    //   minWidth: 200,
    //   renderCell: (params) => (
    //     // <MDBox style={{ gap: 10, display: "flex" }}>
    //     //   {userName.map((user, index) => {
    //     //     return (
    //     //       <Tooltip title={user} key={index}>
    //     //         <Avatar
    //     //           sx={{
    //     //             bgcolor: "#1976d2",
    //     //             width: 25,
    //     //             height: 25,
    //     //             fontWeight: 400,
    //     //             padding: 1,
    //     //             fontSize: "16px",
    //     //           }}
    //     //         >
    //     //           {user.charAt(0)}
    //     //         </Avatar>
    //     //       </Tooltip>
    //     //     );
    //     //   })}
    //     // </MDBox>
    //     <>-</>
    //   ),
    // },
    //   {
    //     field: "fullName",
    //     headerName: "Assign To",
    //     description: "This column has a value getter and is not sortable.",
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    //   },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        getRowId={(item) => item?._id}
        rows={
          [
            ...saveData?.[0]?.details?.sort((a, b) => {
              return a.key - b.key;
            }),
          ].filter((saveData) => saveData.isCustomField != true) || []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[50, 100]}
        checkboxSelection
        onRowSelectionModelChange={(rows) => setSelectedRows(rows)}
      />

      {/* {saveData?.[0]?.details.map((question) => (
        <div key={question._id}>
          <h3>{question.questionText}</h3>
          {question.questionType === 'table' ? (
            <DynamicTable
              columns={question.columns}
              rows={question.rows}
              onAddRow={(newRow) => {
                // Handle adding a new row to the tables
                console.log('Adding new row:', newRow);
                // You can update the state or perform other actions here
              }}
            />
          ) : (
            <TextField label="Type your answer here" multiline rows={4} fullWidth />
          )}
        </div>
      ))} */}
    </div>
  );
}
